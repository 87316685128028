export default [
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    route: 'invoice-list',
    // action: ['read', 'update'],
    // resource: 'invoice',
    action: 'manage',
    resource: 'all',
    // children: [
    // {
    //   title: 'List',
    //   route: 'invoice-list',
    // },
    // {
    //   title: 'Preview',
    //   route: { name: 'invoice-preview', params: { id: 4987 } },
    // },
    // {
    //   title: 'Edit',
    //   route: { name: 'apps-invoice-edit', params: { id: 4987 } },
    // },
    // {
    //   title: 'Add',
    //   route: { name: 'apps-invoice-add' },
    // },
    // ],
  },
]
