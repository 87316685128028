export default [
  {
    header: 'Account & Members',
    action: ['read', 'update'],
    resource: 'member',
  },
  {
    title: 'My Account',
    icon: 'UserIcon',
    route: 'my-account',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Manage Member',
    icon: 'UsersIcon',
    action: ['read', 'update'],
    resource: 'member',
    children: [
      {
        title: 'Add Agent',
        route: 'add-agent',
        action: 'update',
        resource: 'agent',
      },
      {
        title: 'Agents',
        route: 'agents',
        action: ['read', 'update'],
        resource: 'agent',
      },
      {
        title: 'Add Sub Account',
        route: 'add-sub-account',
        action: 'update',
        resource: 'member',
      },
      {
        title: 'Sub Accounts',
        route: 'sub-account',
        action: ['read', 'update'],
        resource: 'member',
      },
      {
        title: 'Members',
        route: 'members',
        action: ['read', 'update'],
        resource: 'member',
      },
    ],
  },
  // {
  //   title: 'Agent Invoice',
  //   icon: 'FileTextIcon',
  //   action: ['read', 'update'],
  //   resource: 'member',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-invoice-list',
  //       action: ['read', 'update'],
  //       resource: 'member',
  //     },
  //     {
  //       title: 'Preview',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //       action: ['read', 'update'],
  //       resource: 'member',
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //       action: ['read', 'update'],
  //       resource: 'member',
  //     },
  //   ],
  // },
]
