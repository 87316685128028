/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
import summary from './summary'
import accountAndMember from './account-and-member'
import api from './api'
import settingLotto from './setting-lotto'
import report from './report'
import invoice from './invoice'
// import system from './system'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [...dashboard, ...summary, ...accountAndMember, ...invoice, ...report, ...settingLotto, ...api/* , ...others */]
