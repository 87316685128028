export default [
  {
    header: 'Report',
    resource: 'report',
    action: 'read',
  },
  {
    title: 'Win / Lose',
    icon: 'AwardIcon',
    route: 'win-lose',
    resource: 'report',
    action: 'read',
  },
  {
    title: 'Transaction',
    icon: 'TrelloIcon',
    route: 'transaction',
    resource: 'report',
    action: 'read',
  },
  {
    title: 'Lotto Report',
    icon: 'FileTextIcon',
    route: 'lotto-report',
    resource: 'report',
    action: 'read',
  },
]
