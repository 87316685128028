export default [
  {
    header: 'Lotto',
    action: ['read', 'update'],
    resource: 'lotto',
  },
  {
    title: 'Lotto List',
    icon: 'ListIcon',
    route: 'lotto-list',
    action: ['read', 'update'],
    resource: 'lotto',
  },
  {
    title: 'Lotto Result',
    icon: 'EditIcon',
    route: 'lotto-result',
    action: ['read', 'update'],
    resource: 'lotto',
  },
  {
    title: 'Result History',
    icon: 'BookIcon',
    action: ['read', 'update'],
    resource: 'lotto',
    children: [
      {
        title: 'Lotto',
        route: 'lotto-history',
        action: ['read', 'update'],
        resource: 'lotto',
      },
      {
        title: 'Yeekee',
        route: 'yeekee-history',
        action: ['read', 'update'],
        resource: 'lotto',
      },
    ],
  },
]

// "Action": "read", "Subject": "lotto"
