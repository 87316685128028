export default [
  {
    header: 'Api',
    resource: 'api',
    action: ['read', 'update'],
  },
  {
    title: 'Api Setting',
    icon: 'SettingsIcon',
    route: 'api-setting',
    resource: 'api',
    action: ['read', 'update'],
  },
  {
    title: 'Api Log',
    icon: 'DatabaseIcon',
    route: 'api-log',
    resource: 'api',
    action: ['read', 'update'],
  },
  // {
  //   title: 'Api Fail',
  //   icon: 'CloudOffIcon',
  //   route: 'api-fail',
  //   resource: 'api',
  //   action: ['read', 'update'],
  // },
]
