export default [
  {
    title: 'Summary',
    icon: 'BookIcon',
    tag: '5',
    tagVariant: 'light-info',
    children: [
      {
        title: 'Total Bet',
        route: 'total-bet',
        action: 'read',
        resource: 'summary',
      },
      {
        title: 'Total Pay',
        route: 'total-pay',
        action: 'read',
        resource: 'summary',
      },
      {
        title: 'Total Profit',
        route: 'total-profit',
        action: 'read',
        resource: 'summary',
      },
      {
        title: 'Total Poy',
        route: 'total-poy',
        action: 'read',
        resource: 'summary',
      },
      {
        title: 'Bet Number',
        route: 'bet-number',
        action: 'read',
        resource: 'summary',
      },
    ],
  },
]
