export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    // tag: '2',
    tag: '1',
    tagVariant: 'light-warning',

    children: [
      // {
      //   title: 'eCommerce',
      //   route: 'dashboard-ecommerce',
      //   // route: 'empty1',
      //   action: 'read',
      //   resource: 'dashboard',
      // },
      // {
      //   title: 'Analytics',
      //   route: 'dashboard-analytics',
      //   // route: 'empty2',
      //   action: 'read',
      //   resource: 'dashboard',
      // },
      {
        title: 'Statistics',
        route: 'dashboard-statistics',
        action: 'read',
        resource: 'dashboard',
      },
      // {
      //   title: 'Analytics',
      //   route: 'dashboard-analytics',
      //   action: 'read',
      //   resource: 'dashboard',
      // },
    ],
  },
]
